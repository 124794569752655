<template>
  <div class="d-flex align-items-center">
    <span class="mr-2">{{ params.displayName }}</span>
    <b-button
      class="btn-xs btn p-2 mr-2 justify-content-center align-item-center d-flex"
      v-b-tooltip.hover
      :title="hoverText"
    >
      <i class="fas fa-info m-0 p-0 fa-xs"></i>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'PaxCustomHeader',
  data() {
    return {
      params: null,
      hoverText: 'Zum Filtern kann eine Zahl, eine Range oder </>für die Suche eingegeben werden.',
    };
  },
  beforeMount() {
    this.params = this.params || {};
  },
  mounted() {},
  methods: {},
};
</script>
